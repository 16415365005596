import { useEffectOnce } from "react-use";
import { openInvite } from "shared/client.telefunc";
import { usePageContext } from "src/hooks/usePageContext";
import { useQuery } from "src/hooks/useQuery";
import { setInviteId, setSessionId } from "src/utils/params";
import { withSuspense } from "src/utils/withSuspense";
import { InvalidPage } from "./invalid";
import { Welcome } from "./page";

export const Page = withSuspense(() => {
  const { urlParsed } = usePageContext();
  const inviteId = urlParsed.search.invite;
  const { data: invite } = useQuery(
    ["invite", inviteId],
    () => openInvite(inviteId),
    {
      mode: "server",
    }
  );

  useEffectOnce(() => {
    setSessionId(invite.sessionId);
    setInviteId(inviteId);
  });

  if (!invite || !invite.valid) {
    return <InvalidPage />;
  }

  return <Welcome />;
});
